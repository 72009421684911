// Colors
$blue: #002AFF !default;
$blue-light: lighten($blue, 7.5);
$blue-hover: rgba($blue, 0.7) !default; /* UXD has defined this opacity variation. */
$light-blue: #d8deff !default;
$black: #0A0A0A !default;
$grey: #8ca8ac !default;
$white: #fff !default;
$off-white: #FAF7F5 !default;
$medgrey: #71717A !default;
$grey-base: #505057 !default;
$grey-black: #0A0A0A !default;
$grey-dark: #4a4a4a !default;
$danger: #E51716 !default;
$danger-bg: #fde6e6 !default;
$border-color-1: #e5e9ff !default;

$link-arrow-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='12' fill='none'%3E%3Cpath stroke='%23096FFA' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m1.5 1 5 5-5 5'/%3E%3C/svg%3E") !default;

$close-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2L17.5563 17.5563' stroke='white' stroke-width='2' stroke-linecap='square'/%3E%3Cpath d='M17.5563 2L2 17.5563' stroke='white' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E") !default;
$carret-left-image: url("data:image/svg+xml,%3Csvg width='11' height='19' viewBox='0 0 11 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 10L9.55635 17.5563' stroke='white' stroke-width='2' stroke-linecap='square'/%3E%3Cpath d='M9.55635 2L2 10' stroke='white' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E") !default;
$carret-left-image-black: url("data:image/svg+xml,%3Csvg width='11' height='19' viewBox='0 0 11 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 10L9.55635 17.5563' stroke='black' stroke-width='2' stroke-linecap='square'/%3E%3Cpath d='M9.55635 2L2 10' stroke='black' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E") !default;

//breakpoints
$small: 375px;
$medium: 600px;
$large: 960px;
$xlarge: 1440px;

.billing-history {
  margin: 0 5%;
  @media screen and (min-width: $medium) {
    margin: 0;
  }

  .billing-history-back {
    display: flex;
    align-items: center;
    gap: 15px;
    width: fit-content;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
    img {
      height: 14px;
      padding: 0 8px;
    }
    h1 {
      font-size: 20px;
    }
  }

  table {
    margin: 32px 0;
    width: 100%;
    border-collapse: collapse;
    thead {
      text-align: left;
      border-bottom: solid 1px $black;
      color: $grey-dark;
      font-size: 15px;
      th {
        font-weight: 400;
        padding-bottom: 16px;
      }
    }

    tbody td {
      padding: 16px 0;
      border-bottom: solid 1px $border-color-1;
    }

    // Keeps columns looking ~equidistant, while only some of them are text-aligned center
    .date-column {
      width: 25%;
    }
    .amount-column,
    .receipts-column {
      text-align: center;
    }
  }

  button {
    font-size: 12px;
    padding: 8px 16px;
    text-transform: none;
    letter-spacing: normal;
    margin: 0 auto;
  }
  .subscription__questions.consumer {
    margin-top: 40px;
  }
}
